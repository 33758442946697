<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('taxi.create tourist option')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <AppInput name="TO_name" :label="$t('taxi.TO name')" type="string" rules="required"
                        v-model="entryToSave.name"/>
              <AppInput type="number-length:3" rules="required" name="TO_time" :label="$t('taxi.TO time')"
                        v-model="entryToSave.time"/>
              <AppSelect :options="{placeholder: $t('common.taxi_type')}" name="taxi_type"
                         :label="$t('common.taxi_type')" rules="required"
                         :options-data="taxiTypes"
                         v-model="entryToSave.taxi_type_id">
              </AppSelect>
              <AppInput type="int" rules="required" name="TO_price" :label="$t('common.price')"
                        v-model="entryToSave.price"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('taxi.create tourist option')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <AppInput name="TO_name" :label="$t('taxi.TO name')" type="string" rules="required"
                        v-model="entryToEdit.name"/>
              <AppInput type="number-length:3" rules="required" name="TO_time" :label="$t('taxi.TO time')"
                        v-model="entryToEdit.time"/>
              <AppSelect :options="{placeholder: $t('common.taxi_type')}" name="taxi_type"
                         :label="$t('common.taxi_type')" rules="required"
                         :options-data="taxiTypes"
                         v-model="entryToEdit.taxi_type_id">
              </AppSelect>
              <AppInput type="int" rules="required" name="TO_price" :label="$t('common.price')"
                        v-model="entryToEdit.price"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: this.$t('taxi.tourist option list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.TOURIST_OPTION_LIST,
          params: {"filters[taxi_company_id][equal]": this.$route.params.id}
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.TOURIST_OPTION_DELETE,
        }
      },
      actions: {
        createEntry: this.createEntry,
        others: [
          {
            label: 'コピー',
            click: entry => this.editEntry(entry),
            contentHtml: entry => `<div class="btn-tool default text-center">コピー</div>`,
          },
          {
            label: this.$t('common.delete'),
            click: entry => this.$refs.table.deleteEntry(entry),
            contentHtml: entry => `<div class="btn-tool delete"></div>`,
          }
        ]
      },
      tools: {
        search: {
          filters: []
        }
      },
      entrySelectedDelete: {},
      entryToSave: {plan: 1},
      entryToEdit: {},
      taxi: {},
      taxiTypes: [],
      columns: [
        {name: 'name', label: this.$t('taxi.TO name'), detailHeader: true},
        {name: 'time', label: this.$t('taxi.TO time')},
        {name: 'price', label: this.$t('common.price'), computed: entry => this.formatPriceJa(entry.price)},
        {name: 'taxi_type.name', label: this.$t('common.taxi_type'), sortable: false},
        {name: 'creator', label: this.$t('common.creator'), listShow: false},
        {name: 'created_at', label: this.$t('common.created at'), listShow: false},
        {name: 'updater', label: this.$t('common.updater'), listShow: false},
        {name: 'updated_at', label: this.$t('common.updated at'), listShow: false},
      ]
    }
  },
  components: {BasicTable},
  mounted() {
    this.$request.get(this.$consts.API.ADMIN.TAXI_DETAIL(this.$route.params.id), null, {
      noLoadingEffect: true,
      handleNotFound: true
    }).then(res => {
      if (!res.hasErrors()) {
        this.taxi = res.data.data
        this.$store.commit('setting/updateBreadCrumb', {taxi_company: this.taxi.name})
        this.$request.get(this.$consts.API.ADMIN.TAXI_TYPE_SELECT(this.taxi.id), null, {noLoadingEffect: true}).then(res => {
          if (!res.hasErrors()) {
            this.taxiTypes = res.data.data
          }
        })
      }
    })
  },
  methods: {
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {plan: 1}
      this.$refs.form_create_entry.reset()
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {name, time, price, taxi_type_id} = this.entryToSave
      const res = await this.$request.post(this.$consts.API.ADMIN.TOURIST_OPTION_SAVE, {
        name, time, price, taxi_type_id, taxi_company_id: this.taxi.id
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {plan: 1}
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      // Method này là create from copy, ko phải update, nhưng lợi dụng code từ phần update làm cho nhanh
      const {name, time, price, taxi_type_id} = this.entryToEdit
      const res = await this.$request.post(this.$consts.API.ADMIN.TOURIST_OPTION_SAVE, {
        name, time, price, taxi_type_id, taxi_company_id: this.taxi.id
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
    },
    /* UPDATE entry end */
  }
}
</script>
